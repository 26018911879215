// export  function ({ app, redirect, route }) {
//     // List of routes that do not require authentication
//     const publicRoutes = ['/login', '/signup']
  
//     // Check if the route is in publicRoutes
//     if (publicRoutes.includes(route.path)) {
//       return // Skip token check for public routes
//     }
  
   
  
//     if (!token) {
//       // Redirect to login or unauthorized page if token is not found
//       return redirect('/login') // Adjust the route as per your application's logic
//     }
  
//     // Optionally, you can also validate the token against your backend here
  
//     // Proceed to the next middleware or route
//   }

import  { useUserStore } from '../stores/user.js'
  

export default defineNuxtRouteMiddleware((to, from) => {
    console.log("To : ",to.path)
    const userStore = useUserStore()
    let token
    if(to.path == '/' && to.query.accessMode == 'preview') return

     // Check if the token exists in local storage or cookies
     if(!userStore.isPreview){
        token = localStorage.getItem('token')
     }
     else{
        token = sessionStorage.getItem('token')
     }

    // List of routes that do not require authentication
    const publicRoutes = ['/login', '/signup']
    
    // Check if the route is in publicRoutes
    if (publicRoutes.includes(to.path) && !token) {
        return // Skip token check for public routes
    }

    if(!token){
        return navigateTo('/login')
    }
    else if ((to.path == '/login' || to.path == '/signup') && token) {
        return navigateTo('/')
    }

    return

  })
  